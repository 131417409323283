import { graphql } from 'gatsby';

import PolicyPage from '../templates/PolicyPage';

export default PolicyPage;

export const query = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/" } }) {
      html
      frontmatter {
        title
      }
      headings {
        value
      }
      fields {
        slug
      }
    }
  }
`;
